<template>
  <div>
    <h2>Estado de pago</h2>
    <br />
    <ul>
      <li>
        <v-row>
          <v-col cols="4"> Estado: </v-col>
          <v-col
            cols="8"
            v-if="payment.nice_status === 'En curso'"
            style="color: grey"
          >
            {{ payment.nice_status }}
          </v-col>
          <v-col
            cols="8"
            v-if="payment.nice_status === 'Pagado'"
            style="color: #479b25"
          >
            {{ payment.nice_status }}
          </v-col>
          <v-col
            cols="8"
            v-if="payment.nice_status === 'Vencido'"
            style="color: red"
          >
            {{ payment.nice_status }}
          </v-col>
        </v-row>
      </li>
      <li>
        <v-row>
          <v-col cols="4"> Vencimiento: </v-col>
          <v-col cols="8">
            {{ getLastDateToPay(payment.createdAt) }}
          </v-col>
        </v-row>
      </li>
      <li>
        <v-row>
          <v-col cols="4"> Monto: </v-col>
          <v-col cols="8">
            {{ payment.price }}
          </v-col>
        </v-row>
      </li>

      <li>
        <v-row>
          <v-col cols="4"> Metodo de pago: </v-col>
          <v-col cols="8" v-if="payment.payment_method_id"> {{payment.payment_method.name}} </v-col>
          <v-col cols="8" v-else> No encontrado </v-col>
        </v-row>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "payment",
  computed: {
    paymentId() {
      return this.$route.params.paymentId;
    },
    ...mapState({
      payment: (state) => state.payment.payment,
      fetchingData: (state) => state.fetchingData,
    }),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getPaymentById"]),
    getFetch() {
      this.getPaymentById({ id: this.paymentId });
    },
    getLastDateToPay(f) {
      if (f) {
        const meses = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        const date = new Date(f);
        const lastdate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return `${lastdate.getDate()} de ${meses[
          lastdate.getMonth()
        ].toLowerCase()} del ${lastdate.getFullYear()}`;
      }
    },
  },
  created() {
    console.log(this.$route.params);
    this.getFetch();
  },
};
</script>

<style scope>
li {
  list-style: none;
}
</style>